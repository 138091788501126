import React from 'react';
import Layout from 'components/Layout';
import { ReactQueryConfigProvider } from 'react-query';
import HandoverForceComplete from 'wms/handover/HandoverForceComplete';
import { Router } from '@reach/router';

export default function HandoverForceCompleteContainer(props) {
  return (
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Layout {...props}>
        <Router>
          <HandoverForceComplete path="/wms/handoverForceComplete/:sessionId/:sessionCode" />
        </Router>
      </Layout>
    </ReactQueryConfigProvider>
  );
}

import React, { useState, useReducer, useRef } from 'react';
import Select from 'react-select';
import Button from 'components/inputs/ButtonM';
import Link from 'components/Link';
import { useQuery, useMutation } from 'react-query';
import fetch from 'utils/fetch';
import { useBranchId } from 'utils/customHooks';
import handoverSessionDetailsReducer, {
  actions,
  filterByStatusOptions,
} from './handoverSessionDetailsReducer';
import LinearProgress from '@material-ui/core/LinearProgress';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ConfirmationDialog from 'components/ConfirmationDialog';

export default function HandoverForceComplete(props) {
  const { sessionId } = props;
  const branchId = useBranchId();
  const [state, dispatch] = useReducer(handoverSessionDetailsReducer, {});
  const dialogForceCompleteConfirm = useRef();

  const { refetch: refetchHandoverSessionDetails, isFetching: isFetchingSessionDetails } = useQuery(
    branchId && ['handoverSessionDetails', branchId],
    () =>
      fetch({
        url: `wms/handover/session/${sessionId}/details`,
        method: 'GET',
        headers: {
          'x-wh-id': branchId,
        },
      }),
    {
      onSuccess: (data) => {
        dispatch([actions.INIT, data]);
      },
    }
  );

  const [forceComplete] = useMutation(
    ({ whId, sessionId }) =>
      fetch({
        url: `wms/handover/session/${sessionId}/compelete`,
        method: 'POST',
        params: {
          whId: whId,
        },
      }),
    {
      onSuccess: () => {
        refetchHandoverSessionDetails();
      },
    }
  );

  function onFilterSelected(type, value) {
    dispatch([actions.UPDATE_SELECTED_FILTERS, { type: type, data: value }]);
  }

  function onSearch(value) {
    dispatch([actions.SEARCH, value.toLowerCase()]);
  }

  return (
    <div className="bg-background w-full h-screen">
      <div className="flex flex-col">
        {isFetchingSessionDetails ? (
          <LinearProgress
            classes={{
              colorPrimary: 'bg-green-300',
            }}
          />
        ) : null}

        {/* Header SEction containing Filters */}
        <div className="flex flex-row justify-center items-center my-5 mx-5">
          <div className="flex-1">
            <p className="text-base md:text-2xl antialiased sm:subpixel-antialiased md:antialiased font-semibold md:font-semibold mx-5">
              {state?.info?.code}
            </p>
          </div>
          <div className="flex flex-row-reverse flex-3 justify-center items-center space-x-2">
            <Select
              className="w-52 h-9 rounded-sm md:rounded-sm shadow-sm md:shadow-sm px-2 my-2"
              placeholder="Filter by status"
              value={state?.selectedFilters?.byStatus}
              options={filterByStatusOptions}
              onChange={(value) => onFilterSelected('filterByStatus', value)}
            />
            <Select
              className="w-52 h-9 rounded-sm md:rounded-sm shadow-sm md:shadow-sm px-2 my-2"
              placeholder="Filter by distributor"
              value={state?.selectedFilters?.byDistributor}
              options={state?.distributors}
              onChange={(value) => onFilterSelected('filterByDistributor', value)}
            />
            <input
              className="w-52 h-9 rounded-sm md:rounded-sm shadow md:shadow-lg px-2 my-2"
              placeholder="Search sku by code"
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4">
          {state?.filteredList?.map((item) => {
            return <SkuItem key={item?.id} sku={item} />;
          })}
        </div>

        <div className="fixed bottom-3 inset-x-1/2 justify-center items-center">
          {state?.actualList?.every((item) => item.state === 'COMPLETED') ? (
            <p className="text-lg font-semibold text-green">Completed</p>
          ) : (
            <Button
              onClick={() => {
                //setConfirmDialogVisibility(true);
                dialogForceCompleteConfirm.current.openDialog();
              }}
            >
              Complete
            </Button>
          )}
        </div>
      </div>

      {/* Dialog Section for force complete confirmation  */}
      <ConfirmationDialog
        ref={dialogForceCompleteConfirm}
        title="Please confirm your action"
        desc="Your are attempting to complete the handover operation forcefully. Are you sure about
      your choice ?"
        yes="Yes, Go ahead"
        no="Disagree"
        onYes={() => {
          forceComplete({ whId: branchId, sessionId: sessionId });
        }}
        closeOnYes={true}
      />
    </div>
  );
}

export function SkuItem(props) {
  const { sku } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className="rounded md:rounded-lg shadow md:shadow-lg bg-white m-5 p-3">
      <Link to={`/wms/handoverSkuDetails/${sku.id}/${sku.skuCode}`}>
        {/* Sku Code and status */}
        <div className="flex flex-row flex-grow ml-2">
          <p className={`flex-1 text-base md:text-base font-bold md:font-bold`}>{sku?.sku?.code}</p>
          <p className={`float-right ${skuCard[sku?.state].statusStyle}`}>
            {skuCard[sku?.state].value}
          </p>
        </div>
        {/* Sku name */}
        <p className="flex-1 text-xs md:text-xs font-normal md:font-normal my-1.5 ml-2">
          {sku?.sku?.name}
        </p>
        {/* batch, distributor, type:ptl/gtl */}
        <div className="flex flex-row flex-grow my-2.5 ml-2">
          <p className="flex-1 text-left text-xs md:text-xs font-medium md:font-medium">
            {sku?.batch}
          </p>
          <p className="flex-1 text-center text-xs md:text-xs font-medium md:font-medium">
            {sku?.distributor}
          </p>
          <p className="flex-1 text-right text-xs md:text-xs font-medium md:font-medium">
            {sku?.sku?.type}
          </p>
        </div>
        {/* Full cases & pieces count */}
        <div className="flex flex-row py-2">
          <div className="flex flex-col flex-grow w-20 h-12 rounded md:rounded-lg shadow md:shadow-lg bg-white justify-center items-center mx-1">
            <p className="text-sm md:text-sm font-medium md:font-medium">
              {sku?.L2Progress?.done}/{sku?.L2Progress?.target}
            </p>
            <p className="text-xs md:text-xs">Full Cases</p>
          </div>
          <div className="flex flex-col flex-grow w-20 h-12 rounded md:rounded-lg shadow md:shadow-lg bg-white justify-center items-center mx-1">
            <p className="text-sm md:text-sm font-medium md:font-medium">
              {sku?.L0Progress?.done}/{sku?.L0Progress?.target}
            </p>
            <p className="text-xs md:text-xs">Pieces</p>
          </div>
        </div>
      </Link>
      {/* More options */}
      <div className="flex justify-center items-center">
        <IconButton
          classes={{ root: 'p-0 m-0' }}
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="task-card-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              // deleteHandover(session?.id);
              setAnchorEl(null);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

const skuCard = {
  COMPLETED: {
    statusStyle: 'text-xs md:text-xs font-medium md:font-medium text-green',
    value: 'Completed',
  },
  PENDING: {
    statusStyle: 'text-xs md:text-xs font-medium md:font-medium text-primary',
    value: 'Pending',
  },
  ONGOING: {
    statusStyle: 'text-xs md:text-xs font-medium md:font-medium text-primary',
    value: 'Ongoing',
  },
  CANCELLED: {
    statusStyle: 'text-xs md:text-xs font-medium md:font-medium text-warn',
    value: 'Cancelled',
  },
};

import produce from 'immer';

export const actions = {
  INIT: 'INIT',
  UPDATE_SELECTED_FILTERS: 'UPDATE_SELECTED_FILTERS',
  //FILTER: 'FILTER',
  SEARCH: 'SEARCH',
};

const handoverSessionDetailsReducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    switch (action) {
      case actions.INIT: {
        // creating info object
        const sessionInfo = {
          id: data?.id,
          code: data?.code,
          createdAt: data?.createdAt,
        };

        // creating distributor array for filters object
        const distributors = [{ value: 'ALL', label: 'All' }];
        const set = new Set();
        data?.tasks.forEach((item) => {
          set.add(item.distributor);
        });
        set.forEach((item) => {
          distributors.push({ value: item, label: item });
        });

        // creating object for selected filters
        const selectedFilters = {
          byStatus: { value: 'ALL', label: 'All' },
          byDistributor: { value: 'ALL', label: 'All' },
        };

        return {
          ...data,
          info: sessionInfo,
          distributors: distributors,
          actualList: data?.tasks,
          filteredList: data?.tasks,
          selectedFilters: selectedFilters,
        };
      }

      case actions.UPDATE_SELECTED_FILTERS: {
        let filteredList = [];
        if (data.type === 'filterByStatus') {
          draft.selectedFilters.byStatus = data.data;
          filteredList = getFilteredList(
            {
              byStatus: data.data,
              byDistributor: state?.selectedFilters?.byDistributor,
            },
            state?.actualList
          );
        } else if (data.type === 'filterByDistributor') {
          draft.selectedFilters.byDistributor = data.data;
          filteredList = getFilteredList(
            {
              byStatus: state?.selectedFilters?.byStatus,
              byDistributor: data.data,
            },
            state?.actualList
          );
        }
        draft.filteredList = filteredList;
        break;
      }

      case actions.SEARCH: {
        const searchedList =
          data === ''
            ? state.actualList
            : state.actualList.filter((item) => item.sku?.code.toLowerCase().includes(data));
        draft.filteredList = searchedList;
        break;
      }

      default:
        return state;
    }
  });
};

export default handoverSessionDetailsReducer;

export const filterByStatusOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'ONGOING', label: 'Ongoing' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'CANCELLED', label: 'Cancelled' },
];

function getFilteredList(data, list) {
  let fList = [];

  if (data) {
    if (data.byStatus?.value === 'ALL') {
      if (data.byDistributor?.value === 'ALL') {
        fList = list;
      } else {
        fList = list.filter((item) => item.distributor === data?.byDistributor?.value);
      }
    } else {
      if (data.byDistributor?.value === 'ALL') {
        fList = list.filter((item) => item.state === data?.byStatus?.value);
      } else {
        fList = list.filter(
          (item) =>
            item.distributor === data?.byDistributor?.value && item.state === data?.byStatus?.value
        );
      }
    }
    return fList;
  } else {
    return list;
  }
}
